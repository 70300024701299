import { useState } from 'react';
import { Form, Button, Dropdown } from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import { api } from '../api';

const SiteAssignment = ({ allSites, previouslyAssignedSites, setRender, userId}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [currentlyAssignedSites, setCurrentlyAssignedSites] = useState([...previouslyAssignedSites]);

    const siteOptions = allSites.map(site => ({
        key: site.id,
        text: site.name,
        value: site.name
    }));

    const getSitesToRemove = () => {
        return previouslyAssignedSites.filter(s => !(currentlyAssignedSites.map(s => s.id).includes(s.id)))
    };

    const getSitesToAdd = () => {
        return currentlyAssignedSites.filter(s => !(previouslyAssignedSites.map(s => s.id).includes(s.id)));
    }

    const handleSiteSelection = (_, {value}) => {
        setCurrentlyAssignedSites(allSites.filter(s => value.includes(s.name)));
    }

    const handleSubmit = async () => {
        const sitesToAdd = getSitesToAdd();
        const sitesToRemove = getSitesToRemove();
        const accessToken = await getAccessTokenSilently();
        
        for (let i=0; i<sitesToAdd.length; i++) {
            let success = await api.assignUserToSite(sitesToAdd[i].id, userId, accessToken);
            if (success) setRender(Math.random())
        }

        for (let i=0; i<sitesToRemove.length; i++) {
            let success = await api.removeUserFromSite(sitesToRemove[i].id, userId, accessToken);
            if (success) setRender(Math.random())
        }
    };

    return (
        <>
            <Form>
                <Form.Field>
                    <Dropdown
                        placeholder='Select Sites'
                        selection
                        multiple
                        options={siteOptions}
                        value={currentlyAssignedSites.map(s => s.name)}
                        onChange={handleSiteSelection}
                    />
                </Form.Field>
                
                <Button 
                    onClick={handleSubmit}
                    color='blue'
                    disabled={getSitesToAdd().length === 0 && getSitesToRemove().length === 0}
                >
                    Update
                </Button>
            </Form>
        </>

    );
}

export default SiteAssignment;