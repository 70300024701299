import {
  Header,
  Label,
  Button,
  Grid,
  Table,
  TableHeader,
  Segment,
  Message,
} from "semantic-ui-react";
import { api } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Loader } from "./Loader";

import EditOrderModal from "./EditOrderModal";

const BookingOrders = ({ booking }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(Math.random());
  const [saveStatus, setSaveStatus] = useState({});
  const [isOrderEditorOpen, setIsOrderEditorOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    const accessToken = await getAccessTokenSilently();
    const fetchedOrders = await api.getOrdersForBooking(
      booking.id,
      accessToken
    );
    setOrders(fetchedOrders);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  const handleOverrideSaveClick = async (editedOrder) => {
    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      total: editedOrder.total,
      couponsUsed: editedOrder.couponsUsed,
      preAuthorizedAmount: editedOrder.preAuthorizedAmount,
      status: editedOrder.status,
      braintreeTransactionId: editedOrder.braintreeTransactionId,
      paymentType: editedOrder.paymentType,
      paymentMethodId: editedOrder.paymentMethodId,
    };
    await api
      .patchOrder(editedOrder.id, accessToken, requestObject)
      .then(() => {
        setSaveStatus({ status: "success", errorMessage: "" });
        setRender(Math.random());
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });
  };

  const handleOpenOrderEditor = (order) => {
    setSelectedOrder(order);
    setIsOrderEditorOpen(true);
  };

  const handleCloseOrderEditor = () => {
    setSelectedOrder(null);
    setIsOrderEditorOpen(false);
  };

  return (
    <Loader isLoading={isLoading}>
      <Grid textAlign="left" stackable>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={9}>
            <Header> Booking Orders</Header>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              icon="plus"
              content="New transaction"
              floated="right"
              disabled={true}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="top">
          <Grid.Column width={12}>
            <Table textAlign="center">
              <TableHeader>
                <Table.Row>
                  <Table.HeaderCell> Time created</Table.HeaderCell>
                  <Table.HeaderCell> Status </Table.HeaderCell>
                  <Table.HeaderCell>
                    {" "}
                    Braintree transaction id{" "}
                  </Table.HeaderCell>
                  <Table.HeaderCell> Pre-authorized amount </Table.HeaderCell>
                  <Table.HeaderCell> Coupons used</Table.HeaderCell>
                  <Table.HeaderCell> Total settled </Table.HeaderCell>
                  <Table.HeaderCell> </Table.HeaderCell>
                </Table.Row>
              </TableHeader>

              <Table.Body>
                {orders.map((order) => (
                  <Table.Row key={order.id}>
                    <Table.Cell>
                      <Moment format="llll Z" local>
                        {order.createdAt}
                      </Moment>
                    </Table.Cell>
                    <Table.Cell>
                      <Label circular className={order.status}>
                        {order.status}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>{order.braintreeTransactionId}</Table.Cell>
                    <Table.Cell>
                      ${order.preAuthorizedAmount.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>-${order.couponsUsed.toFixed(2)}</Table.Cell>
                    <Table.Cell>${order.total.toFixed(2)}</Table.Cell>
                    <Table.Cell>
                      <Button
                        icon="edit"
                        onClick={() => handleOpenOrderEditor(order)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Grid.Row textAlign="center">
              {saveStatus.status === "success" && (
                <Message positive>
                  <Message.Header>Save was Successful!</Message.Header>
                </Message>
              )}
              {saveStatus.status === "error" && (
                <Message negative>
                  <Message.Header>
                    Something went wrong - {saveStatus.errorMessage}
                  </Message.Header>
                </Message>
              )}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              Total booking revenue:
              <h3>
                {" "}
                $
                {orders
                  .reduce(
                    (total, order) => total + order.total - order.couponsUsed,
                    0
                  )
                  .toFixed(2)}
              </h3>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {selectedOrder !== null && (
        <EditOrderModal
          isOpen={isOrderEditorOpen}
          onClose={handleCloseOrderEditor}
          order={selectedOrder}
          onSave={(editedOrder) => handleOverrideSaveClick(editedOrder)}
        />
      )}
    </Loader>
  );
};

export default BookingOrders;
