import React, { useCallback, useState } from 'react'
import { Button } from 'semantic-ui-react';

/**
 * Displays a group of buttons that call a function when clicked
 * @param {{
 *  content: string[]
 *  onClick: (text: string) => void
 * }} props
 */
export function ButtonGroup({content, onClick}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleOnClick = useCallback((index, text) => {
    setActiveIndex(index);
    onClick(text);
  }, [onClick]);

  return (
    <Button.Group basic>
        {content.map((text, index) => (
            <Button 
                key={index}
                active={index === activeIndex}
                tabIndex={index}
                onClick={() => handleOnClick(index, text)}
            >
                {text}
            </Button>
        ))}
    </Button.Group>
  )
}
