import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Comment,
  Form,
  Grid,
  Icon,
  Input,
  Label,
  Message,
  Modal,
  Popup,
  Segment,
  Table,
  TextArea,
} from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import { Loader } from "./Loader";
import { isLoading } from "../utils/isLoading";
import { TextInput } from "./TextInput";
import { getPaginatedItems, Pagination } from "./Pagination";

const ChatContext = createContext({
  state: {
    open: false,
    to: "",
    message: "",
    status: null,
  },
  messages: [],
  toggleOpenChatWindow: () => {},
  setChatWindowState: () => {},
  setMessages: () => {},
  updateRecipient: () => {},
});

/**
 * Displays a chat window on top of its children.\
 * Note: useChatActions should be used in the children instead of ChatContext.
 */
export const ChatWindowManager = ({ children }) => {
  const [chatWindowState, setChatWindowState] = useState({
    open: false,
    selectedUser: null,
    to: "",
    message: "",
    status: null,
  });
  const [messages, setMessages] = useState([]);

  const toggleOpenChatWindow = useCallback(
    (props) => {
      setChatWindowState((prev) => ({
        to: props?.sendTo ?? chatWindowState.to,
        selectedUser: props?.selectedUser ?? prev.selectedUser,
        open: props?.windowOpen ?? !prev.open,
        message: props?.message ?? prev.message,
      }));
    },
    [chatWindowState.to]
  );

  const updateRecipient = useCallback((props) => {
    setChatWindowState((prev) => ({
      ...prev,
      selectedUser: props?.user ?? null,
      to: props?.recipient ?? "",
    }));
    setMessages([]);
  }, []);

  return (
    <ChatContext.Provider
      value={{
        state: chatWindowState,
        messages: messages,
        toggleOpenChatWindow: toggleOpenChatWindow,
        setChatWindowState: setChatWindowState,
        setMessages: setMessages,
        updateRecipient: updateRecipient,
      }}
    >
      {children}
      <ChatWindow />
    </ChatContext.Provider>
  );
};

const ChatWindow = () => {
  const messageAreaEndRef = useRef(null);
  const { state, messages, setChatWindowState, setMessages, updateRecipient } =
    useContext(ChatContext);
  const { getAccessTokenSilently } = useAuth0();

  /**
   * Changes the message status and clears the message input if the send attempt was successful
   */
  const updateMessageStatus = useCallback(
    (newStatus) => {
      setChatWindowState((prev) => ({
        ...prev,
        status: newStatus ?? null,
        message: newStatus ? prev.message : "",
      }));
    },
    [setChatWindowState]
  );

  /**
   * Adds a new message to the array of sent messages
   */
  const addNewMessage = useCallback(
    (newMessage) => {
      setMessages((prev) => [...prev, newMessage]);
    },
    [setMessages]
  );

  /**
   * Updates the message input state and resets the message status.
   */
  const handleMessageChange = useCallback(
    (newMessage) => {
      setChatWindowState((prev) => ({
        ...prev,
        status: null,
        message: newMessage,
      }));
    },
    [setChatWindowState]
  );

  /**
   * Attempts to send an SMS message
   */
  const handleSendSMS = useCallback(
    async (e) => {
      e.preventDefault();
      const data = new FormData(e.target);
      const accessToken = await getAccessTokenSilently();

      updateMessageStatus("sending");

      const requestObject = {
        to: addCodesToNumber(data.get("to")),
        message: data.get("message"),
      };

      try {
        const result = await api.sendSms(accessToken, requestObject);

        const localisedDate = new Intl.DateTimeFormat("en-AU", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(result.dateCreated));

        addNewMessage({
          dateCreated: localisedDate,
          from: "Ohmie",
          body: result.messageBody,
        });

        updateMessageStatus();
      } catch {
        updateMessageStatus("failed");
      }
    },
    [addNewMessage, getAccessTokenSilently, updateMessageStatus]
  );

  // Scroll to the latest message every time a new one is added.
  useEffect(() => {
    if (messageAreaEndRef.current !== null) {
      const messageArea = messageAreaEndRef.current.parentElement;
      messageArea.scrollTo(0, messageArea.scrollHeight);
    }
  }, [messages]);

  return (
    <Segment
      basic
      style={{
        position: "fixed",
        bottom: "40px",
        right: "40px",
      }}
    >
      {state.open && (
        <Card raised fluid>
          <Card.Content>
            <Form onSubmit={handleSendSMS}>
              <Form.Field>
                <Input
                  type="tel"
                  name="to"
                  labelPosition="left"
                  placeholder="+61 4## ### ###"
                  value={state.to}
                  onChange={(_, data) =>
                    updateRecipient({ recipient: data.value })
                  }
                  icon
                >
                  <UserListModal />
                  <input
                    minLength={10}
                    maxLength={12}
                    // Accepts '+614 ### ### ###' or '04 #### ####' with any number of spaces between each group
                    pattern="(\+61\s*4\d{2}(\s*\d{3}){2})|(04(\s*\d{4}){2})"
                    required
                  />
                  {state.to && (
                    <Icon
                      name="close"
                      link
                      icon
                      onClick={() => updateRecipient()}
                    />
                  )}
                </Input>
              </Form.Field>

              <Segment
                placeholder
                basic
                style={{
                  padding: 0,
                  justifyContent: "flex-end",
                  maxHeight: "250px",
                }}
              >
                <Comment.Group style={{ margin: 0, overflow: "auto" }}>
                  {messages &&
                    messages.map((message) => (
                      <SMSMessage key={message.dateCreated} message={message} />
                    ))}

                  {state.status === "failed" && (
                    <Message icon negative>
                      <Icon name="times circle" />
                      <Message.Content>
                        <Message.Header>Error</Message.Header>
                        <p>Failed to send message</p>
                      </Message.Content>
                    </Message>
                  )}

                  <div ref={messageAreaEndRef} />
                </Comment.Group>
              </Segment>

              <Form.Field
                error={state.status === "failed"}
                disabled={state.status === "sending"}
              >
                <div className="ui action input">
                  <TextArea
                    name="message"
                    placeholder="Aa"
                    required
                    minLength={1}
                    value={state.message}
                    onChange={(_, { value }) => handleMessageChange(value)}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderRightColor: "transparent",
                      resize: "none",
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={state.status === "sending"}
                    loading={state.status === "sending"}
                    color="blue"
                  >
                    Send
                  </Button>
                </div>
              </Form.Field>
            </Form>
          </Card.Content>
        </Card>
      )}

      <FloatingActionButton />
    </Segment>
  );
};

/**
 * A button that is displayed on top of the page in the corner of the screen.\
 * When clicked, it toggles the chat window open or closed.
 */
const FloatingActionButton = () => {
  const { toggleOpenChatWindow, state } = useContext(ChatContext);

  return (
    <Popup
      trigger={
        <Label
          color="teal"
          size="big"
          pointing={state.open ? "above" : false}
          onClick={() => toggleOpenChatWindow()}
          as="a"
          style={{
            float: "right",
            boxShadow:
              "0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15)",
          }}
        >
          <Icon name="chat" fitted />
        </Label>
      }
      content="Send an SMS message"
      position="left center"
      mouseEnterDelay={800}
    />
  );
};

const SMSMessage = ({ message }) => (
  <Comment>
    <Comment.Content>
      <Comment.Author>
        {message.from}
        <Comment.Metadata>{message.dateCreated}</Comment.Metadata>
      </Comment.Author>
      <Comment.Text>{message.body}</Comment.Text>
    </Comment.Content>
  </Comment>
);

/**
 * Displays a modal that contains a paginated and searchable list of users.\
 * When a user is selected, their name is displayed alongside their number in the recipient input field.
 */
const UserListModal = () => {
  const { state, updateRecipient } = useContext(ChatContext);
  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [users, setUsers] = useState({ state: "loading", value: [] });
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filters, setFilters] = useState({
    searchValue: "",
  });

  /**
   * Returns an array of users that meet the filter conditions.\
   * Each user is added to the array only if every filter is true.
   */
  const applyFilters = useCallback((users, filters) => {
    return users.value.filter((user) => {
      // Search in name, email, or phone number
      const searchMatch =
        filters.searchValue === "" ||
        (user.firstName + " " + user.lastName)
          .toLowerCase()
          .includes(filters.searchValue.toLowerCase()) ||
        user.email.startsWith(filters.searchValue.toLowerCase()) ||
        user.phoneNumber.includes(filters.searchValue.toLowerCase());

      return searchMatch;
    });
  }, []);

  const updateFilters = (updatedFilter) => {
    setFilters((prev) => ({ ...prev, ...updatedFilter }));
  };

  const filterUsers = useCallback(
    (users, filters) => {
      const filteredUserResults = applyFilters(users, filters);
      setFilteredUsers(filteredUserResults);
      setActivePage(1);
    },
    [applyFilters]
  );

  /**
   * Closes the modal and sets the recipient to the selected user.
   */
  const handleClose = useCallback(
    (user) => {
      setOpen(false);
      updateRecipient({ recipient: user.phoneNumber, user: user });
    },
    [updateRecipient]
  );

  /**
   * Opens the modal and fetches the users.
   */
  const handleOpen = useCallback(async () => {
    setOpen(true);

    const accessToken = await getAccessTokenSilently();
    const fetchedUsers = await api.getUsers(accessToken);

    setUsers({ state: "success", value: fetchedUsers });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    filterUsers(users, filters);
  }, [filterUsers, filters, users]);

  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={(e) => handleOpen(e)}
      trigger={
        <Label as="a" basic={state.selectedUser === null} horizontal>
          {state.selectedUser
            ? `${state.selectedUser.firstName} ${state.selectedUser.lastName}`
            : "To"}
        </Label>
      }
    >
      <Modal.Header>Select a User to Message</Modal.Header>
      <Modal.Content>
        <Loader isLoading={isLoading(users)}>
          <Grid stackable>
            <Grid.Column width={4}>
              <TextInput
                placeholder="Name, email or phone"
                onSubmit={(searchValue) => updateFilters({ searchValue })}
              />
            </Grid.Column>
          </Grid>

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email Address</Table.HeaderCell>
                <Table.HeaderCell>Phone Number</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {getPaginatedItems(filteredUsers, activePage).map((user) => (
                <Table.Row key={user.id} onClick={() => handleClose(user)}>
                  <Table.Cell>{`${user.firstName} ${user.lastName}`}</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>{user.phoneNumber}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <Grid stackable>
            <Grid.Column width={4}>
              <Pagination
                activePage={activePage}
                onPageChange={(page) => setActivePage(page)}
                itemQuantity={filteredUsers.length}
              />
            </Grid.Column>
          </Grid>
        </Loader>
      </Modal.Content>
    </Modal>
  );
};

function addCodesToNumber(phoneNumber) {
  const number = phoneNumber.replaceAll(" ", "");

  if (number.length === 10) {
    return "+61" + number.slice(1);
  }
}

export function useChatActions() {
  const { toggleOpenChatWindow } = useContext(ChatContext);
  return { toggleOpenChatWindow };
}
