import { Header, Grid } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import DriversLicence from '../components/DriversLicence';
import UserDetails from '../components/UserDetails';
import UserCoupons from '../components/UserCoupons';
import SiteAssignment from '../components/SiteAssignment';
import SiteAssignmentRequests from '../components/SiteAssignmentRequests';
import { Loader } from '../components/Loader';
import { api } from '../api';

const User = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [render, setRender] = useState(Math.random());
    const [user, setUser] = useState({});
    const [allSites, setAllSites] = useState([]);
    const [usersSiteAssignmentRequests, setUsersSiteAssignmentRequests] = useState([]);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const fetchedUser = await api.getUserById(id, accessToken)
            setUser(fetchedUser);

            const fetchedSites = await api.getSites(accessToken);
            setAllSites(fetchedSites);
            
            const fetchedSiteAssignmentRequests = await api.getUsersSiteAssignmentRequests(id, accessToken)
            setUsersSiteAssignmentRequests(fetchedSiteAssignmentRequests);

            setIsLoading(false);
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [render])

    return (
        <Loader isLoading={isLoading}>
            <Grid columns={3} stackable>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h3' textAlign='center'>User details</Header>
                        <UserDetails user={user} />
                        <UserCoupons user={user} />
                    </Grid.Column>

                    <Grid.Column>
                        <Header as='h3' textAlign='center'>Driver's Licence Details</Header>
                        <DriversLicence
                            driversLicence={user.driversLicense}
                            verified={user.driversLicenceVerified}
                            setRender={setRender}
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Header as='h3' textAlign='center'>Assigned Sites</Header>
                        <SiteAssignment
                            allSites={allSites}
                            previouslyAssignedSites={user.sites}
                            setRender={setRender}
                            userId={id}
                        />
                        <SiteAssignmentRequests requests={usersSiteAssignmentRequests} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Loader>
    )
}

export default User;