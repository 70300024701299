import React, { useState } from 'react';
import { Modal, Icon, Button, Table, Input, Dropdown, Label } from 'semantic-ui-react';

const EditOrderModal = ({ isOpen, onClose, order, onSave }) => {
    const [editedOrder, setEditedOrder] = useState('');

    const handleEditField = (fieldName, value) => {
        setEditedOrder({ ...editedOrder, [fieldName]: value });
    }

    const handleSave = () => {
        editedOrder.id = order.id;
        editedOrder.braintreeTransactionId = order.braintreeTransactionId;
        onSave(editedOrder);
        onClose();
    };

    const orderStatusOptions = [
        {
            key: 'Pending',
            text: <span> <Label circular className="pending"> pending </Label> </span>,
            value: 'pending',
        },
        {
            key: 'Authorized',
            text: <span> <Label circular className="authorized"> authorized</Label> </span>,
            value: 'authorized',
        },
        {
            key: 'Failed',
            text: <span> <Label circular className="failed"> failed</Label> </span>,
            value: 'failed',
        },
        {
            key: 'Cancelled',
            text: <span> <Label circular className="cancelled"> cancelled</Label> </span>,
            value: 'cancelled',
        },
        {
            key: 'PreAuthPostponed',
            text: <span> <Label circular className="preAuthPostponed"> preAuthPostponed</Label> </span>,
            value: 'preAuthPostponed',
        },
        {
            key: 'PreAuthExpired',
            text: <span> <Label circular className="preAuthExpired"> preAuthExpired</Label> </span>,
            value: 'preAuthExpired',
        }
    ]

    const statusRenderLabel = () => ({
        orderStatusOptions
    })

    const paymentTypeOptions = [
        {
            key: 'Private',
            text: 'Private',
            value: 'private'
        },
        {
            key: 'Work',
            text: 'Work',
            value: 'work'
        },
    ]

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Modal.Header>Override Order Details</Modal.Header>
            <Modal.Content>
                <Table definition columns={11}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={3}/>
                            <Table.HeaderCell width={4}>Old details</Table.HeaderCell>
                            <Table.HeaderCell width={4} >New details</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell> Total</Table.Cell>
                            <Table.Cell> ${order.total.toFixed(2)} </Table.Cell>
                            <Table.Cell>
                                <Input
                                    placeholder={order.total.toFixed(2)}
                                    labelPosition="left"
                                    label="$"
                                    onChange={(e) => handleEditField('total', e.target.value)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> Coupons Used</Table.Cell>
                            <Table.Cell> -${order.couponsUsed.toFixed(2)} </Table.Cell>
                            <Table.Cell>
                                <Input
                                    placeholder={order.couponsUsed.toFixed(2)}
                                    labelPosition="left"
                                    label="-$"
                                    onChange={(e) => handleEditField('couponsUsed', e.target.value)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> Pre Authorized Amount</Table.Cell>
                            <Table.Cell> ${order.preAuthorizedAmount.toFixed(2)} </Table.Cell>
                            <Table.Cell>
                                <Input
                                    placeholder={order.preAuthorizedAmount.toFixed(2)}
                                    labelPosition="left"
                                    label="$"
                                    onChange={(e) => handleEditField('preAuthorizedAmount', e.target.value)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                        <Table.Cell> Status </Table.Cell>
                        <Table.Cell> <Label circular className={order.status}>
                            {order.status} </Label> </Table.Cell>
                        <Table.Cell>
                            <Dropdown
                            placeholder={order.status}
                            selection
                            clearable options={orderStatusOptions}
                            renderLabel={statusRenderLabel}
                            onChange={(_, data) => handleEditField('status', data.value)} />
                        </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> Braintree Transaction Id</Table.Cell>
                            <Table.Cell> {order.braintreeTransactionId} </Table.Cell>
                            <Table.Cell>
                                <Input
                                    placeholder={order.braintreeTransactionId}
                                    onChange={(e) => handleEditField('braintreeTransactionId', e.target.value)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                        <Table.Cell> Payment Type </Table.Cell>
                            <Table.Cell> {order.paymentType} </Table.Cell>
                            <Table.Cell>
                                <Dropdown
                                placeholder={order.paymentType}
                                selection
                                clearable options={paymentTypeOptions}
                                onChange={(_, data) => handleEditField('rate', data.value)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                        <Table.Cell> Payment Method Id </Table.Cell>
                            <Table.Cell> {order.paymentMethodId} </Table.Cell>
                            <Table.Cell>
                            <Input
                                    fluid
                                    placeholder={order.paymentMethodId}
                                    onChange={(e) => handleEditField('paymentMethodId', e.target.value)} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <header>
                Warning! There are no checks to ensure the validity of these changes, please double check all inputs before saving.
                </header>
            </Modal.Content><Modal.Actions>
                <Button color="red" onClick={onClose}>
                    <Icon name="close" /> Cancel
                </Button>
                <Button color="green" onClick={handleSave}>
                    <Icon name="checkmark" /> Save
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default EditOrderModal;