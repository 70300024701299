/**
 * Finds the first non-null time and sets the time to 00:00:00
 * @param {Array} nullableTimes 
 * @returns {Date}
 */
export function nullableTimeToDate (nullableTimes) {
  const nonNullTime = nullableTimes.find(t => t !== null);
  const timeToDate = new Date(nonNullTime);
  timeToDate.setHours(0, 0, 0);

  return timeToDate;
}