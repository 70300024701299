/**
 * Removes any of the characters in "+614" from the start of the given phone number if they exist"
 * @param {string} phoneNumber 
 * @example +6145223590 -> 5223590
 */
export function shortenPhoneNumber (phoneNumber) {
  if(phoneNumber.startsWith("+614")) {
    return phoneNumber.substring(4);
  } else if(phoneNumber.startsWith("+61")) {
    return phoneNumber.substring(3);
  } else if(phoneNumber.startsWith("+6")) {
    return phoneNumber.substring(2);
  } else if(phoneNumber.startsWith("+")) {
      return phoneNumber.substring(1);
  } else {
    return phoneNumber;
  }
}
