import { useState, useEffect, useCallback } from "react";
import { Table, Icon, Popup, Label, Grid } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api/index";
import config from "../config";
import { Loader } from "../components/Loader";
import { Pagination, getPaginatedItems } from "../components/Pagination";
import { TextInput } from "../components/TextInput";
import { isLoading } from "../utils/isLoading";
import { ButtonGroup } from "../components/ButtonGroup";
import { openInNewTab } from "../utils/openInNewTab";

const Sites = () => {
  const navigate = useNavigate();
  const [sites, setSites] = useState({ state: "loading", value: [] });
  const [filteredSites, setFilteredSites] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({
    searchValue: "",
    state: "All",
  });

  const { getAccessTokenSilently } = useAuth0();
  const adminUser = useAuth0().user;

  const stateRoleFilter = useCallback(() => {
    if (adminUser[`${config.auth0.NAMESPACE}/roles`].includes("nsw")) {
      setFilters((prev) => ({ ...prev, state: "NSW" }));
    } else if (adminUser[`${config.auth0.NAMESPACE}/roles`].includes("vic")) {
      setFilters((prev) => ({ ...prev, state: "VIC" }));
    }
  }, [adminUser]);

  const applyFilters = useCallback((sites, filters) => {
    const zipPrefixMap = {
      NSW: "2",
      VIC: "3",
      QLD: "4",
    };

    return sites.value.filter((site) => {
      // Search in site name
      const searchMatch =
        filters.searchValue === "" ||
        site.name.toLowerCase().includes(filters.searchValue.toLowerCase());

      // Show all sites, or only sites where the zip code is the selected zip code
      const stateMatch =
        filters.state === "All" ||
        site.zip.toString().startsWith(zipPrefixMap[filters.state]);

      return searchMatch && stateMatch;
    });
  }, []);

  const filterSites = useCallback(
    (sites, filters) => {
      const filteredSiteResults = applyFilters(sites, filters);
      setFilteredSites(filteredSiteResults);
      setActivePage(1);
    },
    [applyFilters]
  );

  const updateFilters = (updatedFilter) => {
    setFilters((prev) => ({ ...prev, ...updatedFilter }));
  };

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const fetchedSites = await api.getSites(accessToken);

    setSites({ state: "success", value: fetchedSites });
    stateRoleFilter();

    setFilteredSites(fetchedSites);
  }, [getAccessTokenSilently, stateRoleFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    filterSites(sites, filters);
  }, [filterSites, filters, sites]);

  const handleGetVehicleBookingsClick = (site) => {
    navigate(`${site.id}/vehiclebookings?name=${site.name}`);
  };

  return (
    <>
      <Grid stackable>
        <Grid.Column width={4}>
          <TextInput
            placeholder="Site name"
            onSubmit={(updatedFilter) =>
              updateFilters({ searchValue: updatedFilter })
            }
          />
        </Grid.Column>
        <Grid.Column>
          <ButtonGroup
            content={["All", "NSW", "VIC", "QLD"]}
            onClick={(state) => updateFilters({ state })}
          />
        </Grid.Column>
      </Grid>

      <Loader isLoading={isLoading(sites)}>
        <Table selectable textAlign="center">
          <Table.Header>
            <Table.Row>
              {[
                "Name",
                "Address 1",
                "Zip",
                "Working Hours",
                "Type",
                "Actions",
                "Services",
              ].map((header) => (
                <Table.HeaderCell>{header}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getPaginatedItems(filteredSites, activePage).map((site) => (
              <Table.Row
                key={site.id}
                onClick={() => handleGetVehicleBookingsClick(site)}
                onMouseUp={(e) =>
                  openInNewTab(
                    e,
                    `${site.id}/vehiclebookings?name=${site.name}`
                  )
                }
              >
                <Table.Cell>{site.name}</Table.Cell>
                <Table.Cell>{site.address1}</Table.Cell>
                <Table.Cell>{site.zip}</Table.Cell>
                <Table.Cell>
                  {site.businessHoursStart} - {site.businessHoursEnd}
                </Table.Cell>
                <Table.Cell>{site.type}</Table.Cell>
                <Table.Cell>
                  <ActionButton
                    popupText="Show assigned users"
                    iconName="users"
                    destination={`${site.id}/users`}
                  />
                  <ActionButton
                    popupText="Show vehicle bookings"
                    iconName="calendar"
                    destination={`${site.id}/vehiclebookings?name=${site.name}`}
                  />
                </Table.Cell>
                <Table.Cell textAlign="left">
                  {site.servicesAvailable.map((service) => (
                    <Label key={service} className={service}>
                      {service}
                    </Label>
                  ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Grid stackable>
          <Grid.Column width={4}>
            <Pagination
              activePage={activePage}
              onPageChange={(page) => setActivePage(page)}
              itemQuantity={filteredSites.length}
            />
          </Grid.Column>
        </Grid>
      </Loader>
    </>
  );
};

const ActionButton = ({ popupText, iconName, destination }) => {
  const navigate = useNavigate();

  const handleButtonClick = useCallback(
    (e, destination) => {
      e.stopPropagation();
      navigate(destination);
    },
    [navigate]
  );

  return (
    <Popup
      mouseEnterDelay={800}
      content={popupText}
      trigger={
        <Icon
          link
          name={iconName}
          color="blue"
          size="large"
          onClick={(e) => handleButtonClick(e, destination)}
          onMouseUp={(e) => openInNewTab(e, destination)}
        />
      }
    />
  );
};

export default Sites;
