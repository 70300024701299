import React, { useState } from 'react';
import { Modal, Icon, Button, Table, Input, Dropdown, Label, Message } from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker';
import { now } from 'moment';

const EditBookingModal = ({ isOpen, onClose, booking, onSave }) => {
  const [editedBooking, setEditedBooking] = useState(booking);

  const options = { "hourCycle": "h24", timeZoneName: "shortOffset" };

  const handleEditField = (fieldName, value) => {
    setEditedBooking({ ...editedBooking, [fieldName]: value });
  }

  const handleSave = () => {
    onSave(editedBooking);
    onClose();
  };

  const bookingStatusOptions = [
    {
      key: 'Approved',
      text: <span> <Label className="approved"> approved </Label> </span>,
      value: 'approved',
    },
    {
      key: 'Ended',
      text: <span> <Label className="ended"> ended</Label> </span>,
      value: 'ended',
    },
    {
      key: 'Cancelled',
      text: <span> <Label className="cancelled"> cancelled</Label> </span>,
      value: 'cancelled',
    },
    {
      key: 'Ongoing',
      text: <span> <Label className="ongoing"> ongoing</Label> </span>,
      value: 'ongoing',
    },
  ]

  const statusRenderLabel = () => ({
    bookingStatusOptions
  })

  const bookingRateOptions = [
    {
      key: 'Weekend',
      text: 'Weekend',
      value: 'weekend'
    },
    {
      key: 'Normal',
      text: 'Normal',
      value: 'normal'
    },
  ]

  function milesToKm(miles) {
    return miles * 1.60934
  }

  function kmToMiles(km) {
    return km * 0.621371
  }

  const displayEditWarningLabel = () => {
    if (booking.nextBookingTime === null)
      return <></>

    if (new Date(editedBooking.endTime) > new Date(booking.nextBookingTime))
      {
        return <Message negative><Icon color='red' name='exclamation circle' size='large' floated='right' /> Conflict!
        <br></br>Next booking time: {new Date(booking.nextBookingTime).toLocaleString("en-AU", options)}</Message>
      }
    return <></>
  }

  return (
    <Modal open={isOpen} onClose={onClose} size="small">
      <Modal.Header>Override Booking Details</Modal.Header>
      <Modal.Content>
        <Table definition collapsing >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell >Old details</Table.HeaderCell>
              <Table.HeaderCell >New details</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell > Start Time: </Table.Cell>
              <Table.Cell> {new Date(booking.startTime).toLocaleString("en-AU", options)} </Table.Cell>
              <Table.Cell>
                <DateTimePicker disableClock={true} onChange={(time) => handleEditField('startTime', time)} value={new Date(editedBooking.startTime)} />
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell> End Time: </Table.Cell>
              <Table.Cell> {new Date(booking.endTime).toLocaleString("en-AU", options)} </Table.Cell>
              <Table.Cell>
                <DateTimePicker disableClock={true} onChange={(time) => handleEditField('endTime', time)} value={new Date(editedBooking.endTime)} />
                {displayEditWarningLabel()}
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell> Actual Start Time: </Table.Cell>
              <Table.Cell> {booking.actualStartTime == null ? 'N/A' : new Date(booking.actualStartTime).toLocaleString("en-AU", options)} </Table.Cell>
              <Table.Cell>
                <DateTimePicker disableClock={true} onChange={(time) => handleEditField('actualStartTime', time)} value={editedBooking.actualStartTime == null ? '' : new Date(editedBooking.actualStartTime)} />
              </Table.Cell>
              <Table.Cell>
                <Button icon compact labelPosition='left' color='teal' size='small' onClick={() => handleEditField('actualStartTime', new Date (now()))}>
                                                    <Icon name='clock' />
                                                    Now
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell> Actual End Time: </Table.Cell>
              <Table.Cell> {booking.actualEndTime == null ? 'N/A' : new Date(booking.actualEndTime).toLocaleString("en-AU", options)} </Table.Cell>
              <Table.Cell>
                <DateTimePicker disableClock={true} onChange={(time) => handleEditField('actualEndTime', time)} value={editedBooking.actualEndTime == null ? '' : new Date(editedBooking.actualEndTime)} />                
              </Table.Cell>
              <Table.Cell>
                <Button icon compact labelPosition='left' color='teal' size='small' onClick={() => handleEditField('actualEndTime', new Date (now()))}>
                                                    <Icon name='clock' />
                                                    Now
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell> Status </Table.Cell>
              <Table.Cell> <Label className={booking.status}>
                {booking.status} </Label> </Table.Cell>
              <Table.Cell>
                <Dropdown
                  placeholder={booking.status}
                  fluid
                  selection
                  clearable options={bookingStatusOptions}
                  renderLabel={statusRenderLabel}
                  onChange={(_, data) => handleEditField('status', data.value)} />
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell> Rate </Table.Cell>
              <Table.Cell> {booking.rate} </Table.Cell>
              <Table.Cell>
                <Dropdown
                  placeholder={booking.rate}
                  fluid
                  selection
                  clearable options={bookingRateOptions}
                  onChange={(_, data) => handleEditField('rate', data.value)} />
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell> Start Km: </Table.Cell>
              <Table.Cell> {booking.mileageBefore === 0 ? 'N/A' : milesToKm(booking.mileageBefore).toFixed(2)} </Table.Cell>
              <Table.Cell>
                <Input
                  placeholder={milesToKm(booking.mileageBefore).toFixed(2)}
                  labelPosition="right"
                  label="km"
                  onChange={(e) => handleEditField('mileageBefore', kmToMiles(e.target.value))} />
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell> End Km: </Table.Cell>
              <Table.Cell>  {booking.mileageAfter === 0 ? 'N/A' : milesToKm(booking.mileageAfter).toFixed(2)} </Table.Cell>
              <Table.Cell>
                <Input
                  placeholder={milesToKm(booking.mileageAfter).toFixed(2)}
                  labelPosition="right"
                  label="km"
                  onChange={(e) => handleEditField('mileageAfter', kmToMiles(e.target.value))} />
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <header>
          Warning! There are no checks to ensure the validity of these changes, please double check all inputs before saving.
        </header>
      </Modal.Content><Modal.Actions>
        <Button color="red" onClick={onClose}>
          <Icon name="close" /> Cancel
        </Button>
        <Button color="green" onClick={handleSave}>
          <Icon name="checkmark" /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditBookingModal;