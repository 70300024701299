import { Header, Grid, Label, Button, Tab, TabPane } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { api } from '../api';
import { Loader } from '../components/Loader';
import { useUserAccess } from '../components/UserAccessManager';

import VehicleDetails from '../components/VehicleDetails';
import ChargerControls from '../components/ChargerControls';
import EditVehicleModal from '../components/EditVehicleModal';
import DamageReportsList from '../components/damageReportList';

const VehicleOverview = () => {
    const { isCommunityManager } = useUserAccess();

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [render, setRender] = useState(Math.random());
    const [vehicle, setVehicle] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const [isVehicleEditorOpen, setIsVehicleEditorOpen] = useState(false);
    const [sites, setSites] = useState([]);
    const [vehicleDamages, setVehicleDamages] = useState([]);

    const damagesPanes = [
        {
            menuItem: 'Verified (known)',
            render: () => <TabPane className='damageTabs'><DamageReportsList damages={filterDamages('verified')}
                labels={false}
                vehicleBadges={false} /></TabPane>,
        },
        {
            menuItem: 'New',
            render: () => <TabPane className='damageTabs'><DamageReportsList damages={filterDamages('found')}
                labels={false}
                vehicleBadges={false} /></TabPane>,
        },
        {
            menuItem: 'Archived',
            render: () => <TabPane className='damageTabs'><DamageReportsList damages={filterDamages('archived')}
                labels={false}
                vehicleBadges={false} /></TabPane>,
        },
        {
            menuItem: 'Discarded',
            render: () => <TabPane className='damageTabs'><DamageReportsList damages={filterDamages('discarded')}
                labels={false}
                vehicleBadges={false} /></TabPane>,
        },
    ]

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const fetchedVehicle = await api.getVehicleById(id, accessToken);
            setVehicle(fetchedVehicle);

            const fetchedSites = await api.getSites(accessToken);
            setSites(fetchedSites);

            const fetchedDamages = await api.getDamageReportsForVehicle(id, accessToken);
            setVehicleDamages(fetchedDamages);
            
            setIsLoading(false);
        }
        fetchData()
    }, [render])

    const handleOverrideSaveClick = async (editedVehicleRequest) => {
        const accessToken = await getAccessTokenSilently();
        const requestObject = {
            siteId: editedVehicleRequest.siteId,
            etagNumber: editedVehicleRequest.etagNumber
        }

        await api.patchVehicle(editedVehicleRequest.id, accessToken, requestObject)
            .then(() => {
                setSaveStatus({ status: 'success', errorMessage: '' });
                setRender(Math.random());
            }).catch((_) => {
                setSaveStatus({ status: 'error', errorMessage: 'API Error. Contact Ike' });
            });
    }

    const filterDamages = (type) => {
        return vehicleDamages.filter(damage =>
            damage.type === type)
    };

    const handleOpenVehicleEditor = () => {
        setIsVehicleEditorOpen(true);
    };

    const handleCloseVehicleEditor = () => {
        setIsVehicleEditorOpen(false);
    };

    console.log(filterDamages('verified'))

    return (
        <Loader isLoading={isLoading}>
            <Grid centered stackable columns={2} >
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h3' textAlign='center'>Vehicle details</Header>
                        <VehicleDetails vehicle={vehicle} site={vehicle.site} />

                        {!isCommunityManager && vehicle.type === 'Vehicle' && (
                            <Button
                                color='blue'
                                floated='right'
                                icon='edit'
                                content='Edit vehicle'
                                onClick={() => handleOpenVehicleEditor()}
                            />
                        )}

                        <EditVehicleModal
                            isOpen={isVehicleEditorOpen}
                            onClose={handleCloseVehicleEditor}
                            vehicle={vehicle}
                            sites={sites}
                            onSave={(editedVehicleRequest) => handleOverrideSaveClick(editedVehicleRequest)}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                        <Header as='h3'>Controls</Header>
                        <Grid.Row textAlign='center' centered columns={1}>
                            {(vehicle.type === 'Charger') ? <ChargerControls vehicle={vehicle} site={vehicle.site} setRender={setRender} /> : <Label size='large'> No controls available.</Label>}
                        </Grid.Row>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                    <Header as='h3'>
                        Damages
                    </Header>
                </Grid.Row>
                <Grid.Column width={12}>
                    <Tab menu={{ secondary: true, pointing: true, renderActiveOnly: false }} panes={damagesPanes} />
                </Grid.Column>
            </Grid>
        </Loader>
    )
}

export default VehicleOverview;