import React from 'react'
import { Grid, Button, Image, Modal } from 'semantic-ui-react'

const ImageModal = ({ imageUrl }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      basic
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>View image</Button>}
    >
      <Modal.Content>
          <Grid>
              <Grid.Column textAlign='center'>
                <Image src={imageUrl} />
              </Grid.Column>
          </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default ImageModal