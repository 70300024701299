/**
 * Opens the provided relative url in a new tab if the middle mouse button was clicked
 * @param {MouseEvent} e
 * @param {string} to
 */
export function openInNewTab(e, to) {
  if (e.button === 1) {
    window.open(`${window.location.href}/${to}`);
  }
}
